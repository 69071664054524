import React, { useState, useRef, useEffect } from 'react'
import { motion, useMotionValue, useViewportScroll } from 'framer-motion'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames/bind'
import propTypes from 'prop-types'

import useIsDesktop from 'lib/useIsDesktop'

import useUIContext from 'context/ui'

import { Container } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'

import Filter from './Filter'
import CategoryInfo from './CategoryInfo'

import s from './FilterWithInfo.module.scss'
const cn = classNames.bind(s)

const onPageScrolled = (v, t, check, fn) => {
  if (v > t && !check) {
    fn(true)
    return
  }
  if (v <= t && check) {
    fn(false)
  }
}

// Filter with info: Desktop sticky / Mobile fixed
const FilterWithInfo = ({ scrollArea, categories, activeCategory, onFilterClick, recalc }) => {
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false)

  const toggleHiddenHeader = useUIContext((s) => s.toggleHiddenHeader)
  const isHeaderHidden = useRef(useUIContext.getState().isHeaderHidden)
  useEffect(() => useUIContext.subscribe((s) => (isHeaderHidden.current = s.isHeaderHidden)), [])

  const isDesktop = useIsDesktop()
  const [viewportWidth, viewportHeight] = useWindowSize()
  const { scrollY } = useViewportScroll()

  const virtualY = useRef(useUIContext.getState().scrollY)
  useEffect(() => useUIContext.subscribe((s) => (virtualY.current = s.scrollY)), [])
  const y = useMotionValue(0)

  const filterWithInfo = useRef()
  const info = useRef()
  const rects = useRef({ filterWithInfo: {}, scrollArea: {}, info: {} })
  const offset = useRef(0)
  const rAFId = useRef(null)

  // Desktop behavior
  useEffect(() => {
    if (!filterWithInfo.current || !scrollArea.current || !info.current) return
    rects.current = {
      filterWithInfo: filterWithInfo.current.getBoundingClientRect(),
      scrollArea: scrollArea.current.getBoundingClientRect(),
      info: info.current.getBoundingClientRect(),
    }
    offset.current = parseInt(window.getComputedStyle(filterWithInfo.current).paddingTop)
  }, [viewportWidth, viewportHeight, activeCategory, recalc])

  useEffect(() => {
    let hasStartedScrolling = false

    const onRAF = () => {
      const minScroll = virtualY.current.get() - offset.current
      const maxScroll =
        rects.current.scrollArea.height - rects.current.filterWithInfo.height - rects.current.info.height
      const scroll = Math.min(Math.max(0, minScroll), maxScroll)
      y.set(scroll)

      if (y.get() > 1 && !hasStartedScrolling) hasStartedScrolling = true
      hasStartedScrolling && onPageScrolled(y.get(), 0, isHeaderHidden.current, toggleHiddenHeader)

      if (isDesktop) {
        rAFId.current = window.requestAnimationFrame(onRAF)
      }
    }

    isDesktop && window.requestAnimationFrame(onRAF)

    return () => {
      rAFId.current && window.cancelAnimationFrame(rAFId.current)
      rAFId.current = null
      y.set(0)
    }
  }, [virtualY, y, isDesktop, activeCategory])

  // Mobile behavior
  useEffect(() => {
    if (isDesktop) return
    return scrollY.onChange((v) => {
      onPageScrolled(v, 0, isHeaderHidden.current, toggleHiddenHeader)
      onPageScrolled(v, 100, isMobileFilterVisible, setIsMobileFilterVisible)
    })
  }, [scrollY, isDesktop, isMobileFilterVisible])

  return (
    <TransitionState>
      {({ mount, transitionStatus, current } = {}) => {
        const appear = transitionStatus === 'entering' || transitionStatus === 'entered'
        return (
          <aside ref={filterWithInfo} className={cn(s.filterWithInfo, appear && s.appear)}>
            <motion.div className={s.desktopSticky} style={{ y }}>
              <Filter
                categories={categories}
                activeCategoryId={activeCategory?._meta.uid}
                onFilterClick={onFilterClick}
              />
              <Container>
                <Row>
                  <Column12 initial={12} desktop={4} large={3}>
                    <div className={s.categoryInfoWrapper}>
                      <div ref={info} className={s.categoryInfoInner}>
                        {activeCategory?.content && <CategoryInfo category={activeCategory} />}
                      </div>
                    </div>
                  </Column12>
                  <Column12 large={1} />
                </Row>
              </Container>
            </motion.div>
            <motion.div
              className={s.mobileFilter}
              initial={{ y: '-100%' }}
              animate={{ y: isMobileFilterVisible ? '0%' : '-100%' }}
              transition={{ duration: isMobileFilterVisible ? 0.25 : 0.2 }}
              aria-hidden
            >
              <Filter
                categories={categories}
                activeCategoryId={activeCategory?._meta.uid}
                onFilterClick={onFilterClick}
              />
            </motion.div>
          </aside>
        )
      }}
    </TransitionState>
  )
}

export default FilterWithInfo

FilterWithInfo.propTypes = {
  scrollArea: propTypes.object,
  categories: propTypes.array,
  activeCategory: propTypes.object,
  onFilterClick: propTypes.func,
  recalc: propTypes.number,
}
