import { useState, useLayoutEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'

import { desktop } from 'styles/config/_breakpoints.scss'

const useIsDesktop = () => {
  const [viewportWidth] = useWindowSize(0)
  const [isDesktop, setIsDesktop] = useState(false)
  useLayoutEffect(() => setIsDesktop(viewportWidth >= parseInt(desktop), [viewportWidth, desktop]))
  return isDesktop
}

export default useIsDesktop
