import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { play, pause } from 'lib/videoControls'
import classNames from 'classnames/bind'

import useIsDesktop from 'lib/useIsDesktop'

import Image from 'components/ui/Image'
import Video from 'components/ui/Video'
import CursorHover from 'components/ui/CursorHover'
import { CURSOR_ICONS } from 'components/ui/Cursor'

import ViewportEnter from 'components/motion/ViewportEnter'

import s from './Project.module.scss'
const cn = classNames.bind(s)

const ProjectMedia = ({ webm, mp4, image, alt }) => {
  const [isInView, setIsInView] = useState(false)

  const hasVideo = !!webm || !!mp4
  const videoRef = useRef(null)
  const [renderVideo, setRenderVideo] = useState(false)
  const [shouldPlay, setShouldPlay] = useState(false)

  const isDesktop = useIsDesktop()

  const onViewportEnter = () => {
    hasVideo && isDesktop && setRenderVideo(true)
    setIsInView(true)
  }
  const onMouseEnter = () => hasVideo && isDesktop && setShouldPlay(true)
  const onMouseLeave = () => hasVideo && isDesktop && setShouldPlay(false)

  useEffect(() => {
    if (!videoRef.current) return
    shouldPlay ? play(videoRef.current) : pause(videoRef.current)

    return () => {
      pause(videoRef.current)
    }
  }, [shouldPlay])

  return (
    <ViewportEnter onEnter={onViewportEnter} once={true}>
      <div className={cn(s.media, isInView && s.inView)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {image && (
          <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_PLAY}>
            <div className={s.coverImage}>
              <Image preload="eager" src={image} alt={alt} />
            </div>
          </CursorHover>
        )}
        {hasVideo && renderVideo && (
          <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_PLAY}>
            <Video className={s.video} mp4={mp4 || null} webm={webm || null} ref={videoRef} autoplay={false} />
          </CursorHover>
        )}
      </div>
    </ViewportEnter>
  )
}

export default ProjectMedia

ProjectMedia.propTypes = {
  webm: PropTypes.string,
  mp4: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
}
