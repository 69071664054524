import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { animate } from 'framer-motion'

import { Headline4 } from 'components/ui/Typography'

import s from './FilterWithInfo.module.scss'
const cn = classNames.bind(s)

const Filter = ({ categories, activeCategoryId, onFilterClick }) => {
  const x = useRef({
    start: 0,
    scroll: 0,
    delta: 0,
  })
  const isAnimatingScroll = useRef(false)
  const isDragging = useRef(false)
  const lerped = useRef(0)

  const list = useRef()
  const [activeItem, setActiveItem] = useState(null)

  const start = (e) => {
    isDragging.current = true

    x.current.start = e.pageX - list.current.offsetLeft
    x.current.scroll = list.current.scrollLeft

    lerped.current = list.current.scrollLeft
  }

  const move = (e) => {
    if (!isDragging.current) return
    e.preventDefault()
    x.current.delta = e.pageX - list.current.offsetLeft - x.current.start
    list.current.scrollLeft = x.current.scroll - x.current.delta * 3
  }

  const reset = () => {
    if (!isDragging.current) return
    setTimeout(() => {
      isDragging.current = false
    }, 0)
  }

  const animateScroll = (from, to, duration) => {
    animate(from, to, {
      onPlay: () => {
        if (!list.current) return
        isAnimatingScroll.current = true
      },
      onUpdate: (value) => {
        if (!list.current) return
        list.current.scrollLeft = value
      },
      onComplete: () => {
        if (!list.current) return
        isAnimatingScroll.current = false
      },
      duration,
    })
  }

  useEffect(() => {
    if (!activeItem || !list.current) return
    activeItem && animateScroll(list.current.scrollLeft, activeItem.offsetLeft, 0.6)

    document.documentElement.addEventListener('mousemove', move)
    document.documentElement.addEventListener('mouseleave', reset)
    document.documentElement.addEventListener('mouseup', reset)
    return () => {
      document.documentElement.removeEventListener('mousemove', move)
      document.documentElement.removeEventListener('mouseleave', reset)
      document.documentElement.addEventListener('mouseup', reset)
    }
  }, [activeItem])

  const onItemHovered = (e) => {
    if (window.matchMedia('(hover: none)').matches) return
    if (isDragging.current || isAnimatingScroll.current) return

    const item = e.currentTarget
    const rect = item.getBoundingClientRect()

    const from = list.current.scrollLeft
    let to = 0

    if (rect.x - list.current.offsetLeft < 0) {
      // left edge // to = from + rect.x - list.current.offsetLeft
      to = from - 20 // only hint
    } else if (rect.x + rect.width > window.innerWidth - list.current.offsetLeft) {
      // right edge  // to = from + (rect.x + rect.width - (window.innerWidth - list.current.offsetLeft))
      to = from + 20 // only hint
    }
    to && animateScroll(from, to, 0.5)
  }

  return (
    <div className={s.filter}>
      <ul ref={list} className={s.filterList} onMouseDown={start}>
        {categories.map((c) => {
          return (
            <li
              ref={activeCategoryId === c._meta.uid ? setActiveItem : null}
              className={cn(s.filterItem, activeCategoryId === c._meta.uid && s.isActive)}
              key={c._meta.uid}
              data-category={c._meta.uid}
              onClick={onFilterClick}
              onMouseEnter={onItemHovered}
            >
              <Headline4 className={s.filterTitle} data-title={c.title}>
                {c.title}
              </Headline4>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Filter.propTypes = {
  categories: PropTypes.array.isRequired,
  activeCategoryId: PropTypes.string,
  onFilterClick: PropTypes.func.isRequired,
}

export default Filter
