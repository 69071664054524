import React, { useState } from 'react'
import propTypes from 'prop-types'
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'

import { PrismicLink } from 'prismic'

import { BodyCopy, Subtitle } from 'components/ui/Typography'
import ProjectMedia from './ProjectMedia'

import s from './Project.module.scss'
const cn = classNames.bind(s)

const _ = {
  exitLength: 1.5,
  exitDelay: 0, // before unmounting //TODO - tweak for page transition
  entryLength: 2, // duration of "entering" class before switching to "entered"
  entryDelay: 1.5, // before mounting new page
}

// TODO: transitions, media component
const Project = ({ index, project, onProjectClick }) => {
  const { title, tagline, landscape_imageSharp: image, landscape_video_webm: webm, landscape_video_mp4: mp4 } = project
  const [clicked, setClicked] = useState(false)

  const onClick = () => {
    setClicked(true)
    onProjectClick()
  }

  return (
    <TransitionState>
      {({ mount, transitionStatus, current } = {}) => {
        const appear = transitionStatus === 'entering' || transitionStatus === 'entered'
        return (
          <article className={cn(s.project, appear && s.appear, clicked && s.clicked)}>
            <TransitionLink
              className={s.link}
              to={PrismicLink.url(project)}
              exit={{
                length: _.exitLength,
                delay: _.exitDelay,
                state: { to: 'PROJECT_PAGE' },
              }}
              entry={{
                lenght: _.entryLength,
                delay: _.entryDelay,
              }}
              aria-label="project"
              onClick={onClick}
            >
              <ProjectMedia webm={webm?.url} mp4={mp4?.url} image={image} alt={title[0].text} />
              <div className={s.text}>
                <Subtitle className={s.subtitle}>{tagline[0].text}</Subtitle>
                <BodyCopy as="h2">{title[0].text}</BodyCopy>
              </div>
            </TransitionLink>
          </article>
        )
      }}
    </TransitionState>
  )
}

export default Project

Project.propTypes = {
  index: propTypes.number,
  project: propTypes.object,
  onProjectClick: propTypes.func,
}
