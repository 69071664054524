import React from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'

import { RichText } from 'prismic'

import { BodyCopy } from 'components/ui/Typography'

import ViewsSVG from 'assets/svg/project-views.inline.svg'
import AwardsSVG from 'assets/svg/project-awards.inline.svg'

import s from './FilterWithInfo.module.scss'

const CategoryInfo = ({ category }) => {
  return (
    <motion.div
      className={s.categoryInfo}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.45, delay: 0.25 } }}
    >
      {category.content && RichText.render(category.content)}
      <ul className={s.auxInfo}>
        {category.views && (
          <BodyCopy as="li" className={s.auxItem}>
            <ViewsSVG />
            {category.views} views
          </BodyCopy>
        )}
        {category.awards && (
          <BodyCopy s="li" className={s.auxItem}>
            <AwardsSVG />
            {category.awards} awards
          </BodyCopy>
        )}
      </ul>
    </motion.div>
  )
}

export default CategoryInfo

CategoryInfo.propTypes = {
  category: propTypes.object.isRequired,
}
