import React, { useState, useEffect, useMemo, useRef } from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import { Row, Column12 } from 'components/ui/Grid'

import FilterWithInfo from './FilterWithInfo'
import Projects from './Projects'

import s from './WorkList.module.scss'
const cn = classNames.bind(s)

const WorkList = ({ categories, transitionStatus }) => {
  const appear = transitionStatus === 'entering' || transitionStatus === 'entered'
  const setCursorIcon = useUIContext((s) => s.setCursorIcon)

  const content = useRef()

  const [activeCategoryId, setActiveCategoryId] = useState(null)

  const [projectClicked, setProjectClicked] = useState(false)
  const [reflowRecalc, setReflowRecalc] = useState(0)

  const updateCategory = (category, updateURL) => {
    if (!category) return
    setActiveCategoryId(category)
    updateURL && window.history.pushState(null, '', `?${category}`)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    // Don't update category if navigating to project page
    if (projectClicked) return
    setCursorIcon(null)
    const qs = queryString.parse(window.location.search)

    if (typeof qs !== 'undefined' && Object.values(qs).length) {
      const categoryIds = categories.map((c) => c._meta.uid)
      const qsIds = Object.keys(qs)
      const activeCategoryId = categoryIds.find((c) => qsIds.includes(c))
      // Read category from URL
      updateCategory(activeCategoryId, false)
    } else {
      // Default to first category
      updateCategory(categories[0]._meta.uid, false)
    }
  }, [categories, projectClicked])

  const onFilterClick = (e) => {
    const category = e.target.dataset.category
    updateCategory(category, true)
  }

  const activeCategory = useMemo(() => {
    if (!categories.length || !activeCategoryId) return null
    return categories?.find((c) => c._meta.uid === activeCategoryId)
  }, [categories, activeCategoryId])

  return (
    <div className={cn('wrapper', appear && s.appear)}>
      {categories?.length && (
        <FilterWithInfo
          scrollArea={content}
          categories={categories}
          activeCategory={activeCategory}
          onFilterClick={onFilterClick}
          recalc={reflowRecalc}
        />
      )}
      <div ref={content} className={cn('content')}>
        <Row>
          <Column12 initial={12} desktop={5} large={4} />
          <Column12 initial={12} desktop={7} large={8}>
            {activeCategory && (
              <Projects
                activeCategory={activeCategory}
                onExitComplete={() => setReflowRecalc((s) => s + 1)}
                onProjectClick={() => setProjectClicked(true)}
              />
            )}
          </Column12>
        </Row>
      </div>
    </div>
  )
}

WorkList.propTypes = {
  transitionStatus: PropTypes.string,
  categories: PropTypes.array,
}

export default WorkList
