import React from 'react'
import propTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import { useScrollbar } from '@14islands/r3f-scroll-rig/scrollbar'

import Project from './Project'

import s from './WorkList.module.scss'

const Projects = ({ activeCategory, onExitComplete, onProjectClick }) => {
  const projects = activeCategory?.projects || []

  const { reflow } = useScrollbar()

  return (
    <AnimatePresence
      exitBeforeEnter
      onExitComplete={() => {
        onExitComplete()
        reflow()
      }}
    >
      <motion.div
        key={activeCategory._meta.uid}
        className={s.projects}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.2,
          },
        }}
      >
        {projects.map(({ project }, i) => {
          if (!project) return null
          return (
            <React.Fragment key={project._meta.uid + i + activeCategory}>
              <Project index={i} project={project} onProjectClick={onProjectClick} />
            </React.Fragment>
          )
        })}
      </motion.div>
    </AnimatePresence>
  )
}

export default Projects

Projects.propTypes = {
  activeCategory: propTypes.object,
  onExitComplete: propTypes.func.isRequired,
  onProjectClick: propTypes.func.isRequired,
}
