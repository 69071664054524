import { graphql } from 'gatsby'

import WorkPage from 'views/work/WorkPage'

export const query = graphql`
  query WorkPageQuery {
    prismic {
      page: work_page(uid: "work", lang: "en-us") {
        page_meta_title
        page_meta_description
        page_meta_thumbnail
        title
        project_categories {
          category {
            ... on PRISMIC_Project_category {
              title
              content
              awards
              views
              _meta {
                uid
              }
              projects {
                project {
                  ...PrismicLinkFrag
                  ... on PRISMIC_Project_page {
                    title
                    tagline
                    _meta {
                      uid
                    }
                    landscape_image
                    landscape_imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 1000) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                    landscape_video_webm {
                      ... on PRISMIC__FileLink {
                        url
                      }
                    }
                    landscape_video_mp4 {
                      ... on PRISMIC__FileLink {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

WorkPage.query = query
// WorkPage.fragments = [ProjectCardFrag]

export default WorkPage
