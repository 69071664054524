import React from 'react'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from 'layouts'
import VerticalScroll from 'components/layout/VerticalScroll'
import SEO from 'components/seo'
import WorkList from './components/WorkList'

const WorkPage = ({ data: { prismic }, ...props }) => {
  const { page } = prismic
  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail
  const categories = page?.project_categories.map((c) => c.category)

  return (
    <TransitionState>
      {({ mount, transitionStatus, current } = {}) => (
        <>
          <Layout>
            <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />
            <VerticalScroll>
              <WorkList categories={categories} transitionStatus={transitionStatus} />
            </VerticalScroll>
          </Layout>
        </>
      )}
    </TransitionState>
  )
}

export default WorkPage
